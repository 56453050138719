<template>
  <v-card elevation="0">
    <v-card-text>
      <v-img src="/wip.png" width="100px"></v-img>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { onMounted } from 'vue';
import log from 'loglevel';

onMounted(() => {
  log.info('ViewProfilsSettings mounted');
});
</script>

<style></style>
